import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index.js";

// Common
import Error from "@/views/Error";

// Dashboard
import Dashboard from "@/views/dashboard/Dashboard";
import SendAssessment from "@/views/dashboard/SendAssessment";

// Auth
import SignIn from "@/views/auth/SignIn";
import SignUp from "@/views/auth/SignUp";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ChangePassword from "@/views/auth/ChangePassword";
import RecoverPassword from "@/views/auth/RecoverPassword";
import BillingInfo from "@/views/auth/BillingInfo";

// User Management
import ManageUsers from "@/views/users/ManageUsers";
import InviteUserForm from "@/views/users/InviteUserForm";

// Shop
import ProductList from "@/views/shop/ProductList";
import FinishCheckout from "@/views/shop/FinishCheckout";
import CheckoutController from "@/views/shop/CheckoutController";

// Assessment
import AssessmentStart from "@/views/assessment/AssessmentStart";
import AssessmentForm from "@/views/assessment/AssessmentForm";
import AssessmentQuestions from "@/views/assessment/AssessmentQuestions";
import AssessmentSubmit from "@/views/assessment/AssessmentSubmit";

// Report
import ReportController from "@/views/reports/ReportController";
import TeamDISCReport from "@/views/reports/disc/TeamDISCReport";
import TeamLeaderReport from "@/views/reports/self-leadership/TeamLeaderReport";
import ComparativeDISCReport from "./views/reports/comparative-disc/ComparativeDISCReport.vue";

Vue.use(Router);

// TODO: Refactor routes in a separated file

const router = new Router({
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/error/:message",
      name: "Error",
      component: Error
    },
    {
      path: "/change-password",
      name: "ChangePassword",
      component: ChangePassword
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "/billing-info/:mode",
      name: "BillingInfo",
      component: BillingInfo
    },
    {
      path: "/shop/:type",
      name: "Shop",
      component: ProductList
    },
    {
      path: "/finish-checkout/:status",
      name: "FinishCheckout",
      component: FinishCheckout
    },
    {
      path: "/checkout/:priceId",
      name: "CheckoutController",
      component: CheckoutController
    },
    {
      path: "/recover-password",
      name: "RecoverPassword",
      component: RecoverPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "/signin",
      name: "signin",
      component: SignIn,
      meta: { requiresAuth: false }
    },
    {
      path: "/signup",
      name: "signup",
      component: SignUp,
      meta: { requiresAuth: false }
    },
    // User Managament
    {
      path: "/users",
      name: "users",
      component: ManageUsers,
      meta: { requiresAuth: true }
    },
    {
      path: "/signup/:inviteId",
      name: "SignUp",
      component: SignUp,
      meta: { requiresAuth: false }
    },
    {
      //Added to visualize component template
      path: "/invite-user",
      name: "inviteuser",
      component: InviteUserForm
    },
    // Dashboard
    {
      path: "/",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/assessment/send",
      name: "SendAssessment",
      component: SendAssessment
    },
    {
      path: "/assessment/:assessmentId/resend",
      name: "ResendAssessment",
      component: SendAssessment
    },
    // Assessments
    {
      path: "/assessment/:assessmentId/start/",
      name: "AssessmentStart",
      component: AssessmentStart,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/start/:applicantId",
      name: "AssessmentStart",
      component: AssessmentStart,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/form",
      name: "AssessmentForm",
      component: AssessmentForm,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/questions",
      name: "AssessmentQuestions",
      component: AssessmentQuestions,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/submit",
      name: "AssessmentSubmit",
      component: AssessmentSubmit,
      meta: { requiresAuth: false }
    },
    // Report
    {
      path: "/report/:reportId",
      name: "ReportController",
      component: ReportController,
      meta: { requiresAuth: false }
    },
    {
      path: "/multimap",
      name: "TeamDISCReport",
      component: TeamDISCReport,
      meta: { requiresAuth: false }
    },
    {
      path: "/selfleadermap",
      name: "TeamLeaderReport",
      component: TeamLeaderReport,
      meta: { requiresAuth: false }
    },
    {
      path: "/comparativedisc",
      name: "ComparativeDISCReport",
      component: ComparativeDISCReport,
      meta: { requiresAuth: false }
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const requiresAuth = getIfRequiresAuth(to);
  const { query } = to;
  if (requiresAuth) {
    const isAuth = store.getters.isUserAuthenticated;
    if (isAuth) {
      next(true);
    } else {
      store.dispatch("setRedirectRoute", to);
      next(getNextPath(query.action));
    }
  } else {
    next(true);
  }
});

function getIfRequiresAuth(route) {
  return !route.matched.some(record => record.meta.requiresAuth === false);
}

function getNextPath(action) {
  if (action && action === "signup") {
    return "/signup";
  }
  return "/signin";
}

export default router;
