<template>
  <v-container fluid class="py-0 px-0 view-container" id="printPage">
    <report-header id="ready_to_know" class="topMarginNegative80">
      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col class="d-flex justify-end pr-sm-10 pr-2">
            <!-- <v-btn elevation="2" @click="onPrint">Imprimir</v-btn> -->
            <!-- <o-tooltip value="Test">
              <v-btn>Test</v-btn>
            </o-tooltip> -->
            <v-btn
              v-if="showComparativeReport"
              elevation="2"
              @click="redirectToComparativeReport"
            >
              Generar Reporte Comparativo
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <multimap-graphic id="personal_graphic" :data="data" />
  </v-container>
</template>

<script lang="js">
import ReportHeader from "../common/ReportHeader.vue";
import MultimapGraphic from "@/views/reports/common/disc/MultimapGraphic";

export default {
  name: "TeamReport",
  components: {
    "report-header": ReportHeader,
    "multimap-graphic": MultimapGraphic
  },
  data() {
    return {
      data: []
    };
  },
  computed: {

    showComparativeReport() {
      return (
        this.data &&
        this.data.length <= 2 &&
        this.data.some(item => item.modelType === "ROLE_DISC")
      );
    }
  },
  methods: {
    getReportData() {
      const reportIds = this.$route.query.id;
      if (reportIds && reportIds.length > 0) {
        this.data = [];
        const reportPromises = reportIds.map(id => {
          return this.$store.dispatch("getReportData", id)
            .then(response => {
              this.data.push(response);
            })
            .catch(error => {
              console.log(error);
            });
        });

        return Promise.all(reportPromises);
      }
    },
    redirectToComparativeReport() {
      const reportIds = this.$route.query.id;
      if (reportIds && reportIds.length > 0) {
        this.$router.push({
          path: "/comparativedisc",
          query: { id: reportIds }
        });
      }
    }
  },
  mounted() {
       this.getReportData();
  }
};
</script>

<style scoped>
@media print {
  @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
  .topMarginNegative80 {
    margin-top: -80px;
  }
  html {
    overflow-y: auto;
  }
  body {
    width: 12in;
    height: 18in;
    padding-top: 5px;
  }
}
</style>
