<template>
  <o-page-layout mode="wide">
    <o-card-layout page-mode="wide">
      <v-container fluid>
        <v-row>
          <v-col class="text-left pt-0 pt-md-3" md="6" cols="10">
            <div>
              <span class="text-subtitle-2">Estado de la Membresía:</span>
              {{ subscriptionInfo.status }}
            </div>
            <div>
              <span class="text-subtitle-2">Fecha de Expiración:</span>
              {{ subscriptionInfo.expiresAt }}
            </div>
          </v-col>

          <v-col
            cols="2"
            class="text-right content-align-center pt-0"
            v-if="isXS && !purchaseRequired"
          >
            <o-dropdown-menu
              icon="fas fa-ellipsis-v"
              :actions="mainAdditionalActions"
            />
          </v-col>

          <v-spacer v-if="!!isXS"></v-spacer>

          <v-col class="text-right pt-0 pt-md-3" md="6" cols="12">
            <v-row class="text-center">
              <v-col class="px-0">
                <div class="text-h5">{{ stats.available }}</div>
                <div class="text-subtitle-2">Disponibles</div>
              </v-col>

              <v-col class="px-0">
                <div class="text-h5">{{ stats.sent }}</div>
                <div class="text-subtitle-2">Enviados</div>
              </v-col>

              <v-col class="px-0">
                <div class="text-h5">{{ stats.submitted }}</div>
                <div class="text-subtitle-2">Aplicados</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="text-right justify-space-between align-center">
          <v-col cols="12" md="6">
            <ll-filter
              :initialValue="filter"
              :disabled="viewMultimapRecords"
              @filter="applyFilter"
            />
          </v-col>

          <v-spacer />

          <v-col cols="12" md="auto" v-if="!isXS">
            <v-btn
              class="btn"
              :disabled="!selfLeadermapSelectionCount"
              @click="goToLeaderReport()"
            >
              <v-icon left>fas fa-users</v-icon>
              Autoliderazgo
              {{
                selfLeadermapSelectionCount
                  ? ` (${selfLeadermapSelectionCount})`
                  : ""
              }}
            </v-btn>

            <v-tooltip bottom v-if="selfLeadermapSelectionCount">
              <template v-slot:activator="{ on, attr }">
                <v-btn
                  icon
                  small
                  class="mx-1"
                  v-bind="attr"
                  v-on="on"
                  @click="clearselfLeadermapSelection"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </template>
              <span>Remueve todos registros de Autoliderazgo</span>
            </v-tooltip>

            <v-tooltip bottom v-if="selfLeadermapSelectionCount">
              <template v-slot:activator="{ on, attr }">
                <v-btn
                  class="mr-3"
                  icon
                  small
                  v-bind="attr"
                  v-on="on"
                  @click="viewselfLeadermapRecords = !viewselfLeadermapRecords"
                >
                  <v-icon
                    :color="viewselfLeadermapRecords ? 'error' : undefined"
                  >
                    fas fa-eye{{ viewselfLeadermapRecords ? "-slash" : "" }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  viewselfLeadermapRecords
                    ? "Oculta los registros seleccionados para Autoliderazgo."
                    : "Muestra los registros seleccionados para Autoliderazgo."
                }}
              </span>
            </v-tooltip>
            <v-btn
              class="btn"
              :disabled="!multimapSelectionCount"
              @click="goToTeamReport()"
            >
              <v-icon left>far fa-map</v-icon>
              DISC Multimap
              {{ multimapSelectionCount ? ` (${multimapSelectionCount})` : "" }}
            </v-btn>

            <v-tooltip bottom v-if="multimapSelectionCount">
              <template v-slot:activator="{ on, attr }">
                <v-btn
                  icon
                  small
                  class="mx-1"
                  v-bind="attr"
                  v-on="on"
                  @click="clearMultimapSelection"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </template>
              <span>Remueve todos registros del DISC Multimap</span>
            </v-tooltip>

            <v-tooltip bottom v-if="multimapSelectionCount">
              <template v-slot:activator="{ on, attr }">
                <v-btn
                  class="mr-3"
                  icon
                  small
                  v-bind="attr"
                  v-on="on"
                  @click="viewMultimapRecords = !viewMultimapRecords"
                >
                  <v-icon :color="viewMultimapRecords ? 'error' : undefined">
                    fas fa-eye{{ viewMultimapRecords ? "-slash" : "" }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  viewMultimapRecords
                    ? "Oculta los registros seleccionados para el DISC Multimap."
                    : "Muestra los registros seleccionados para el DISC Multimap."
                }}
              </span>
            </v-tooltip>
          </v-col>

          <v-col
            class="text-right content-align-center"
            cols="12"
            md="auto"
            :sm="purchaseRequired ? 6 : 5"
            v-if="!isXS"
          >
            <v-btn
              color="primary"
              @click="sendAssessment"
              v-if="!purchaseRequired"
            >
              <v-icon left dark>far fa-file</v-icon>
              Nueva Prueba
            </v-btn>

            <v-btn color="error" @click="purchaseMoreAssessments" v-else>
              <v-icon left dark>fas fa-shopping-cart</v-icon>
              Comprar Pruebas
            </v-btn>
          </v-col>

          <v-col
            md="auto"
            class="text-right content-align-center"
            v-if="!isXS && !purchaseRequired"
          >
            <o-dropdown-menu
              icon="fas fa-ellipsis-v"
              :actions="mainAdditionalActions"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              id="assessments-table"
              dense
              fixed-header
              item-key="id"
              :footer-props="{ itemsPerPageText: 'Filas' }"
              :headers="headers"
              :height="tableHeightStr"
              :items="assessmentsToShow"
              :loading="loading"
              :page.sync="page"
              :items-per-page.sync="pageSize"
              :server-items-length="totalCount"
              :hide-default-footer="viewMultimapRecords"
              @update:page="fetchAssessments"
              @update:items-per-page="fetchAssessments"
            >
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-spacer />

                  <v-tooltip
                    left
                    v-if="
                      !isXS &&
                      (item.modelType === 'DISC' ||
                        item.modelType === 'ROLE_DISC') &&
                      item.status === 'SUBMITTED'
                    "
                  >
                    <template v-slot:activator="{ on, attr }">
                      <v-btn
                        class="ml-5"
                        icon
                        x-small
                        v-bind="attr"
                        v-on="on"
                        @click="() => onSelectForMultimap(item)"
                      >
                        <v-icon
                          :color="multimapSelection[item.id] ? 'error' : ''"
                        >
                          far fa-map
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>
                      {{
                        multimapSelection[item.id]
                          ? "Remover del DISC Multimap"
                          : "Agregar al DISC Multimap"
                      }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    left
                    v-if="
                      !isXS &&
                      item.modelType === 'SELF_LEADERSHIP' &&
                      item.status === 'SUBMITTED'
                    "
                  >
                    <template v-slot:activator="{ on, attr }">
                      <v-btn
                        class="ml-5"
                        icon
                        x-small
                        v-bind="attr"
                        v-on="on"
                        @click="() => onSelectForselfLeadermap(item)"
                      >
                        <v-icon
                          :color="
                            selfLeadermapSelection[item.id] ? 'error' : ''
                          "
                        >
                          fas fa-users
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>
                      {{
                        selfLeadermapSelection[item.id]
                          ? "Remover de Autoliderazgo"
                          : "Agregar de Autoliderazgo"
                      }}
                    </span>
                  </v-tooltip>

                  <o-dropdown-menu
                    icon="fas fa-ellipsis-v"
                    :disabled="viewMultimapRecords"
                    :actions="getActions(item)"
                  />
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </o-card-layout>
  </o-page-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";

import purchaseGuardMixin from "@/mixins/purchaseGuardMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import { ASSESSMENT_STATUS, MEMBERSHIP_STATUS, MODEL_TYPES } from "@/model/assessment/constants";
import { getTableHeight, PAGE_SIZE } from "./config";
import Filter from "./Filter.vue";

const allStatus = Object.keys(ASSESSMENT_STATUS);
const allTypes = Object.keys(MODEL_TYPES);

export default defineComponent({
  mixins: [purchaseGuardMixin, messageDialogMixin],
  components: {
    "ll-filter": Filter
  },
  data() {
    return {
      subscriptionInfo: null,
      // Data Table
      headers: [
        {
          text: "Tipo",
          align: "start",
          value: "type"
        },
        {
          text: "Aplicante / Evaluado",
          align: "start",
          value: "name"
        },
        {
          text: "",
          sortable: false,
          align: "start",
          value: "email"
        },
        {
          text: "Estado",
          align: "center",
          value: "statusText"
        },
        {
          text: "Enviado",
          align: "center",
          value: "sentAt"
        },
        {
          text: "Aplicado",
          align: "center",
          value: "submittedAt"
        },
        {
          text: "",
          sortable: false,
          aligh: "end",
          value: "actions"
        }
      ],
      tableOptions: {
        loading: false,
        page: 1,
        last: null,
        loadMore: true
      },
      assessments: [],
      mainAdditionalActions: [
        {
          label: "Comprar Pruebas",
          icon: "fas fa-shopping-cart",
          handler: this.purchaseMoreAssessments
        }
      ],
      filter: {
        searchText: "",
        status: "ALL",
        type: "ALL"
      },
      page: 1,
      pageSize: PAGE_SIZE,
      totalCount: 0,
      loading: false,
      multimapSelection: {},
      selfLeadermapSelection: {},
      viewMultimapRecords: false,
      viewselfLeadermapRecords: false
    };
  },
  computed: {
    assessmentsToShow() {
        if (this.viewMultimapRecords) {
          return Object.values(this.multimapSelection);
        }

        if (this.viewselfLeadermapRecords) {
          return Object.values(this.selfLeadermapSelection);
        }

        return this.assessments;
      },
    stats() {
      return (
        this.$store.getters.accountInfo.accountStats || {
          available: 0,
          sent: 0,
          submitted: 0
        }
      );
    },
    tableHeightStr() {
      return `${getTableHeight(window, !this.viewMultimapRecords)}px`;
    },
    filteredAssessments() {
      let result = this.assessments.filter(assessment => {
        return this.filter.status.includes(assessment.status);
      });

      if (this.filter.searchText.length > 0) {
        const filter = this.filter.searchText.toLowerCase();
        result = result.filter(assessment => {
          return (
            assessment.applicantName.toLowerCase().includes(filter) ||
            assessment.applicantEmail.toLowerCase().includes(filter) ||
            assessment.receiverName.toLowerCase().includes(filter) ||
            assessment.receiverEmail.toLowerCase().includes(filter)
          );
        });
      }

      return result;
    },
    isXS() {
      return this.$vuetify.breakpoint.name == "xs";
    },
    purchaseRequired() {
      return this.stats.available === 0;
    },
    multimapSelectionCount() {
      return Object.keys(this.multimapSelection).length;
    },
    selfLeadermapSelectionCount() {
      return Object.keys(this.selfLeadermapSelection).length;
    }
  },
  methods: {
    sendAssessment() {
      this.$router.push("/assessment/send");
    },
    async applyFilter(filter) {
      this.filter = { ...filter };
      await this.fetchAssessments();
    },
    async fetchAssessments() {
  this.assessments = [];
  const { searchText, status, type } = this.filter;

  const opts = {
    text: searchText || "",
    status: !status || status === "ALL" ? allStatus : [status],
    type: !type || type === "ALL" ? allTypes : [type],
    sortField: "sent_at",
    sortType: "desc",
    page: this.page,
    pageSize: this.pageSize
  };

  try {
    this.loading = true;

    const response = await this.$store.dispatch("listAssessments", opts);

    this.totalCount = response.count;
    this.assessments = response.data.map(item => {
      const applicantName = item.applicantName ? item.applicantName : '-';
      const applicantEmail = item.applicantEmail ? ` (${item.applicantEmail})` : '';
      const assessedName = item.assessedName ? item.assessedName : '-';
      const assessedEmail = item.assessedEmail ? ` (${item.assessedEmail})` : '';

      const name = `${applicantName}${applicantEmail} / ${assessedName}${assessedEmail}`;

      return {
        ...item,
        type: MODEL_TYPES[item.modelType || "DISC"],
        name: name 
      };
    });
  } catch (error) {
    console.error(error);
  } finally {
    this.loading = false;
  }
},
    getActions(assessment) {

      const actions = [];

      if (assessment.status === "SENT") {
        actions.push({
          label: "Reenviar Prueba",
          icon: "fas fa-paper-plane",
          handler: () => this.resendAssessment(assessment)
        });

        actions.push({
          label: "Eliminar Prueba",
          icon: "fas fa-trash",
          handler: () => {
            this.deleteAssessment(assessment)
          }
        });

        if (assessment.modelType === "ONE_TO_ONE" || assessment.modelType === "F1_TEAM") {
          actions.push({
          label: "Cerrar Prueba",
          icon: "fa fa-lock",
          handler: () => {
            this.closeAssessment(assessment)
          }
          });
        }
      } else if (assessment.status === "SUBMITTED") {
        actions.push({
          label: "Ver Reporte",
          icon: "fas fa-file-pdf",
          handler: () => this.viewAssessmentReport(assessment)
        });

        actions.push({
          label: "Reenviar Reporte",
          icon: "fas fa-paper-plane",
          handler: () => this.resendAssessment(assessment)
        });
      }

      return actions;
    },
    resendAssessment(assessment) {
      this.$router.push(`/assessment/${assessment.id}/resend`);
    },
    deleteAssessment(assessment) {
      this.decisionDialog(
        `Está por eliminar la prueba de ${assessment.applicantName} de manera irreversible. ¿Desea continuar con la operación?`,
        [
          { label: "No" },
          {
            label: "Sí",
            handler: () => this.deleteAssessmentProceed(assessment)
          }
        ]
      );
    },
    closeAssessment(assessment) {
      this.decisionDialog(
        `Está por cerrar la prueba de ${assessment.assessedName} de manera irreversible. ¿Desea continuar con la operación?`,
        [
          { label: "No" },
          {
            label: "Sí",
            handler: () => this.closeAssessmentProceed(assessment)
          }
        ]
      );
    },
    goToTeamReport() {
      const reportIds = Object.values(this.multimapSelection).map(
        item => item.reportId
      );

      this.$router.push({
        path: "/multimap",
        query: { id: reportIds }
      });
    },
    goToLeaderReport() {
      const reportIds = Object.values(this.selfLeadermapSelection).map(
        item => item.reportId
      );
      this.$router.push({
        path: "/selfLeadermap",
        query: { id: reportIds}
      });
    },
    deleteAssessmentProceed(assessment) {
      this.$store
        .dispatch("deleteAssessment", assessment.id)
        .then(() => {
          this.successDialog("La eliminación de la prueba fue exitosa", () => {
            this.fetchAssessments();
            this.$store.dispatch("getAccountInfo");
          });
        })
        .catch(error => {
          const { response } = error;

          if (response.status === 409) {
            this.errorDialog(response.data.message);
          } else {
            this.errorDialog("Ha ocurrido un error en la operación");
          }
        });
    },
    closeAssessmentProceed(assessment) {
      this.$store
        .dispatch("closeAssessment", assessment.id)
        .then(() => {
          this.successDialog("El cierre de la prueba fue exitoso", () => {
            this.fetchAssessments();
            this.$store.dispatch("getAccountInfo");
          });
        })
        .catch(error => {
          const { response } = error;

          if (response.status === 409) {
            this.errorDialog(response.data.message);
          } else {
            this.errorDialog("Ha ocurrido un error en la operación");
          }
        });
    },
    viewAssessmentReport(assessment) {
      this.$router.push(`/report/${assessment.reportId}`);
    },
    purchaseMoreAssessments() {
      this.$router.push("/shop/pack");
    },
    onSelectForMultimap(assessment) {
      if (this.multimapSelection[assessment.id]) {
        const { [assessment.id]: unselectedAssessment, ...rest } =
          this.multimapSelection;

        this.multimapSelection = { ...rest };
      } else {
        this.multimapSelection = {
          ...this.multimapSelection,
          [assessment.id]: assessment
        };
      }

      if (this.multimapSelectionCount === 0) {
        this.viewMultimapRecords = false;
      }
    },
    onSelectForselfLeadermap(assessment) {
      if (this.selfLeadermapSelection[assessment.id]) {
        const { [assessment.id]: unselectedAssessment, ...rest } =
          this.selfLeadermapSelection;

        this.selfLeadermapSelection = { ...rest };
      } else {
        this.selfLeadermapSelection = {
          ...this.selfLeadermapSelection,
          [assessment.id]: assessment
        };
      }

      if (this.selfLeadermapSelectionCount === 0) {
        this.viewselfLeadermapRecords = false;
      }
    },
    clearMultimapSelection() {
      this.multimapSelection = {};
      this.viewMultimapRecords = false;
    },
    clearselfLeadermapSelection() {
      this.selfLeadermapSelection = {};
      this.viewselfLeadermapRecords = false;
    }
  },
  created() {
    const subscriptionInfo = this.$store.getters.accountInfo.subscriptionInfo;

    this.subscriptionInfo = {
      status: subscriptionInfo
        ? MEMBERSHIP_STATUS[subscriptionInfo.status]
        : "-",
      expiresAt: subscriptionInfo
        ? new Date(subscriptionInfo.expiresAt * 1000).toLocaleDateString(
            "es-ES"
          )
        : "-"
    };

    (async () => {
      this.filter = { ...this.$store.getters.filter };
      switch(MODEL_TYPES) {
        case "DISC":
          this.multimapSelection = { ...this.$store.getters.multimap };
          break;
          case "ROLE_DISC":
          this.multimapSelection = { ...this.$store.getters.multimap };
          break;
        case "SELF_LEADERSHIP":
          this.selfLeadermapSelection = { ...this.$store.getters.selfLeadermap };
          break;
      }

      await this.fetchAssessments();
    })();

    const floatingAction = this.purchaseRequired
      ? {
          icon: "fas fa-shopping-cart",
          color: "error",
          handler: this.purchaseMoreAssessments
        }
      : {
          icon: "far fa-file",
          color: "primary",
          handler: this.sendAssessment
        };

    this.$emit("set-floating-action", floatingAction);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("setFilter", { ...this.filter });

    this.$store.dispatch("setMultimap", { ...this.multimapSelection });

    this.$store.dispatch("setSelfLeadermap", { ...this.selfLeadermapSelection });

    this.$emit("set-floating-action", null);
    next();
  }
});
</script>

<style scoped>
.btn {
  margin: 0 10px;
}
</style>
