<template>
  <v-row class="ma-0">
    <v-col cols="12" class="pa-1">
      <div
        class="bar"
        :style="{
          background: `linear-gradient(90deg, ${shade}, ${fillColor})`,
          width: `${value * 0.9}%`
        }"
      />
      <div class="text" :style="{ color }">{{ value }}%</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HorizontalBar",
  props: {
    value: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      shade: "rgba(255, 255, 255, 1)"
    };
  },
  computed: {
    fillColor() {
      return this.color || "rgb(0,0,0)";
    }
  },
  mounted() {
    if (this.color) {
      this.shade = this.color;
    }
  }
};
</script>

<style scoped>
.bar {
  height: 2em;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: 600;
  font-size: 1em;
  text-align: right;
  box-shadow: 2px 2px 5px var(--v-accent-base);
  float: left;
}
.text {
  font-weight: 800;
  font-size: 1.5em;
  padding: 0 0 0 0.2em;
  float: left;
  display: flex;
  align-items: stretch;
}
@media print {
  .text {
    font-size: 1.25em;
  }
}
</style>
