<template>
  <div>
    <div class="white pt-8 pb-8">
      <v-row no-gutters>
        <v-col cols="0" md="1" />
        <v-col cols="12" md="10" align="center">
          <report-section-title> Poder de los Factores </report-section-title>

          <xs-rotation-message />
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>

      <div class="d-none d-sm-block d-print-block">
        <v-row
          no-gutters
          v-for="(_, factor) in factors"
          :key="factor"
          class="ma-0 pb-4 no-breakable margin-top"
          :class="{ 'pt-6': factor === 'serenity' }"
        >
          <v-col cols="0" md="1" />
          <v-col cols="12" md="10">
            <factor-power-table
              :factor="factor"
              :data="data"
              :position="position"
            />
          </v-col>
          <v-col cols="0" md="1" />
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import factors from "@/model/roledisc/factors";

import FactorPowerTable from "@/views/reports/roledisc/components/FactorPowerTable.vue";

export default {
  name: "SectionFactorsPower",
  components: {
    "factor-power-table": FactorPowerTable
  },
  props: {
    data: {
      type: Object,
      required: false
    },
    position: {
      type: String,
      required: false
    }
  },
  data() {
    return { factors };
  }
};
</script>

<style scoped>
@media print {
  .no-breakable {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  .margin-top {
    margin-top: 8em;
  }
}
</style>
