<template>
  <div>
    <v-row class="ma-0" align="center">
      <v-col cols="1" class="pl-7">
        <div class="letter-icon" :style="{ backgroundColor: color }">
          {{ letter }}
        </div>
      </v-col>
      <v-col cols="11">
        <div class="factor-title primary--text text-left ml-2">
          {{ title }}
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" class="ma-0">
      <v-col
        align-self="stretch"
        cols="2"
        sm="2"
        class="lat lat-left font-weight-light pa-0"
        v-html="leftText"
      />
      <v-col cols="7" class="pa-0">
        <v-row class="ma-0">
          <v-col class="primary white--text">
            <div class="table-title">{{ question }}</div>
          </v-col>
        </v-row>
        <v-row class="mx-0 my-2 scale-row">
          <v-col
            class="mr-0"
            v-for="(level, index) in levels"
            :key="index"
            :style="{ backgroundColor: `rgba(${rgb}, ${level.alpha})` }"
          >
            <div class="scale-data">{{ level.label }}</div>
          </v-col>
        </v-row>

        <v-row class="ma-0 scale-row">
          <v-col
            class="pa-1 boxed"
            v-for="(frequency, index) in frecuencies"
            :style="frequency.style"
            :key="index"
          >
            <div class="" :class="scaleClasses">
              {{ frequency.label }}
            </div>
          </v-col>
        </v-row>

        <v-row class="mx-0 my-5 position-relative">
          <div
            class="graph-bar"
            :style="{
              backgroundColor: color,
              width: `${naturalValue}%`
            }"
          >
            Natural
          </div>
          <v-col cols="12" class="bar-background" />
        </v-row>

        <v-row class="ma-0 position-relative">
          <div
            class="graph-bar"
            style="background-color: var(--v-background-darken4); color: white"
            :style="{ width: `${adaptiveValue}%` }"
          >
            Adaptado
          </div>
          <v-col cols="12" class="bar-background" />
        </v-row>

        <v-row class="mx-0 my-5 position-relative">
          <div
            class="graph-bar"
            style="background-color: black; color: white"
            :style="{ width: `${roleValue}%` }"
          >
            {{ position }}
          </div>
          <v-col cols="12" class="bar-background" />
        </v-row>
      </v-col>
      <v-col
        align-self="stretch"
        cols="2"
        sm="2"
        class="lat lat-right font-weight-light pa-0"
        v-html="rightText"
      />
    </v-row>
  </div>
</template>

<script>
import factors from "@/model/roledisc/factors";

export default {
  name: "FactorPowerTable",
  props: {
    factor: {
      type: String,
      required: true
    },
    discData: {
      type: Object,
      required: false
    },
    discRoleData: {
      type: Object,
      required: false
    },
    position: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      levels: [
        { alpha: 0.1, label: "Muy bajo", cols: 1 },
        { alpha: 0.1, label: "Bajo", cols: 1 },
        { alpha: 0.2, label: "Medio bajo", cols: 1 },
        { alpha: 0.4, label: "Medio", cols: 1 },
        { alpha: 0.6, label: "Medio alto", cols: 1 },
        { alpha: 0.8, label: "Alto", cols: 1 },
        { alpha: 1, label: "Muy alto", cols: 1 }
      ],
      scaleClasses: {
        "scale-data-xs": this.$vuetify.breakpoint.xs,
        // scaleRowXs: this.$vuetify.breakpoint.sm,
        "scale-data": this.$vuetify.breakpoint.md
      },
      frecuencies: [
        { label: "Siempre eres" },
        { label: "Tiendes a ser" },
        { label: "Algunas veces eres", style: { flexGrow: 3 } },
        { label: "Tiendes a ser" },
        { label: "Siempre eres" }
      ]
    };
  },
  computed: {
    factorData() {
      return factors[this.factor];
    },
    letter() {
      return this.factorData ? this.factorData.letter : "";
    },
    color() {
      return this.factorData ? this.factorData.color : "";
    },
    rgb() {
      return this.factorData ? this.factorData.rgb : "";
    },
    question() {
      return this.factorData ? this.factorData.question : "";
    },
    title() {
      return `Poder del factor  ${this.factorData.colorName}: ${this.factorData.factorPower.keyConcept}`;
    },
    leftText() {
      return this.factorData.factorPower.leftText;
    },
    rightText() {
      return this.factorData.factorPower.rightText;
    },
    naturalValue() {
      return this.discData && this.factorData
        ? this.discData.profiles.primary.values[this.factorData.letter]
            .percentage
        : 0;
    },
    adaptiveValue() {
      return this.discData && this.factorData
        ? this.discData.profiles.conscious.values[this.factorData.letter]
            .percentage
        : 0;
    },
    roleValue() {
      return this.discRoleData && this.factorData
        ? this.discRoleData.results.values[this.factorData.letter].percentage
        : 0;
    }
  }
};
</script>

<style scoped>
.scale-data-xs {
  font-size: 0.4em;
  font-weight: 500;
}
.scale-row {
  display: flex;
  justify-content: space-evenly;
}
.scale-data {
  font-size: 0.5em;
  font-weight: 500;
}
.table-title {
  font-size: 1em;
  font-weight: 500;
}
.factor-title {
  font-size: 1.8em;
  font-weight: 600;
}
.letter-icon {
  width: 1.5em;
  font-size: 1.5em;
  font-weight: 500;
  border-radius: 25px;
  text-align: center;
  color: white;
  padding-left: 1.1px;
}
.boxed {
  background-color: rgba(209, 210, 213, 0);
  border-style: solid;
  border-width: 1px;
  border-color: var(--v-background-darken1);
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
}

.bar-background {
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(244, 244, 244, 1)
  );
  height: 3em;
  top: 0px;
  left: 0px;
}
.position-relative {
  position: relative;
}
.lat {
  font-size: 0.7em;
  background-color: #f4f4f4;
  text-align: left;
}
.lat-left {
  margin-right: 0.5em;
}
.lat-right {
  margin-left: 0.5em;
}
.graph-bar {
  text-align: left;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 2;
  height: 3em;
  display: flex;
  padding-left: 0.5em;
  padding-right: 0.5em;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-weight: 500;
}

@media screen and (min-width: 960px) {
  .scale-data {
    font-size: 0.7em;
  }
  .lat {
    font-size: 0.8em !important;
    padding: 1em !important;
  }
}

@media print {
  .lat {
    font-size: 0.8em;
  }
  .lat-left {
    margin-right: 0.5em;
  }
  .lat-right {
    margin-left: 0.5em;
  }
}
</style>
